<template>
<div>
  <slot name="button" :openPanel="openPanel">

  </slot>
<b-sidebar id="sidebar-contract" width="350px" :title="$t('client.text_68')" backdrop-variant="dark" backdrop right shadow v-model="visible">
  <b-container fluid>
    <b-row class="panel">
      <b-col>
        <b-overlay :show="loading">
          
          <b-row class="mb-4">
            <b-col class="sumary">
              <b-row class="mb-3">
                <b-col cols="4">
                  <b>{{ $t('client.text_69') }}</b><br /> {{ contract.id }}
                </b-col>
                <b-col cols="4">
                  <b>{{ $t('client.text_70') }}</b><br/>
                  <span v-if="contract.status == 'ACTIVE'" class="font-weight-bold text-success">{{ $t('client.text_71') }}</span>
                  <span v-if="contract.status == 'OVERDUE'" class="font-weight-bold text-warning">{{ $t('client.text_72') }}</span>
                  <span v-if="contract.status == 'CANCELED'" class="font-weight-bold text-danger">{{ $t('client.text_73') }}</span>
                </b-col>
                <b-col cols="4">
                  <b>{{ $t('client.text_74') }}</b><br/> {{ contract.start_date | date }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b>{{ $t('client.text_75') }}</b><br/> {{ next_payment }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Product -->
          <b-row class="my-4">
            <b-col>
              <h5 class="d-block">{{ $t('client.text_76') }}</h5>
              <b-row>
                <b-col cols="12" class="product-name">
                  {{ contract.contract.live.product.name }}
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="5">
                  <b-img :src="contract.contract.live.product.images[0].path" fluid class="product-image"></b-img>
                </b-col>
                <b-col cols="7" class="product-description">
                  {{ contract.contract.live.product.description }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- Options -->
          <b-row class="mt-3">
            <b-col>
              <h5 class="mb-3"> {{ $t('client.text_77') }} </h5>
              <b-button type="button" variant="danger" :disabled="contract.status == 'CANCELED' || is_btn_disable" @click="cancelContract">{{ $t('client.text_78') }}</b-button>
            </b-col>
          </b-row>

        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</b-sidebar>
</div>
</template>
<script>
import ContractService from "@/services/resources/ContractService";
import moment from "moment";
const service = ContractService.build();

export default {
  name: 'SubscriptionRightPreview',
  data () {
    return {
      loading: true,
      is_btn_disable: false,
      visible: false,
      contract_id: -1,
      contract: {
        contract:{
          live:{
            product:{
              name: null,
              description: null,
              images:[
                { path: null }
              ]
            }
          }
        },
        start_date: '',
        client:{
          name: null,
          email: null,
          cellphone: null
        },
        history:[
          {
            status: 'processing',
            updated_at: ''
          }
        ]
      }
    }
  },
  computed:{
    next_payment(){
      if(this.contract.history.length > 0){
        if(this.contract.history[0].status == 'paid'){
          return moment(this.contract.history[0].updated_at, "YYYY-MM-DD HH:mm:ss").add(1, 'month').format('DD/MM/YYYY');
        }else{
          return moment(this.contract.history[0].updated_at, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY');
        }
      }

    },
    isSeller(){
      return this.$store.getters.isSeller;
    }
  },
  methods: {
    openPanel(contract_id){
      this.visible = true;
      this.contract_id = contract_id;
      this.fetchContract()
    },
    cancelContract(){

      let data = {
        id: this.contract.id
      };

      service
        .destroy(data)
        .then(response => {
           if(response.success){
            this.is_btn_disable = true;
          
            this.$bvToast.toast(this.$t('client.text_79'), {
              title: 'Contrato',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: true
            });
          }else{
            this.$bvToast.toast(this.$t('client.text_80'), {
              title: this.$t('client.text_68'),
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            });
          }
        })
        .catch(err => {
           this.$bvToast.toast(this.$t('client.text_80'), {
            title: this.$t('client.text_68'),
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          });
        });
    },
    fetchContract(){
      this.loading = true;

      let data = {
        id: this.contract_id
      };

      service
        .read(data)
        .then(response => {
          this.loading = false;
          this.contract = response;

        })
        .catch(err => {
          console.log(err);
        });
    },
  }

  }
</script>

<style lang="scss" scoped>

.panel{
  
  .sumary{
    background: rgb(240, 240, 240);
    font-size: 13px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .product-name{
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  .product-image{
    width: 100%;
  }
  
  .product-description{
    font-size: 14px;
  }


}

</style>